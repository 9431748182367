import { PUB_SUB_EVENTS } from '../helper/constants';
import { publish } from '../helper/pubsub';

/* Variant Select & Radios */
class VariantSelects extends HTMLElement {
  constructor() {
    super();
    this.addEventListener('change', this.onVariantChange);
    // Track the previous variant for state persistence
    this.previousVariant = null;
  }

  onVariantChange() {
    // Store the previous variant before updating
    this.previousVariant = this.currentVariant;

    this.updateOptions();
    this.updateMasterId();
    this.updatePickupAvailability();
    this.removeErrorMessage();

    // If we have a valid variant, update everything
    if (this.currentVariant) {
      this.updateURL();
      this.updateVariantInput();
      this.renderProductInfo();
      this.updateShareUrl();
      // Only update button when we have a valid variant
      this.updateAddButtonState();
    } else if (this.previousVariant) {
      // If we lost a valid variant but had one before, keep the input updated
      // but don't change button state yet (prevents flashing)
      this.updateVariantInput();

      // Check if this is just a transient state during selection
      setTimeout(() => {
        // If after a short delay we still don't have a variant, then update button
        if (!this.currentVariant) {
          this.updateAddButtonState();
        }
      }, 50);
    } else {
      // First load with no variant - update button state
      this.updateAddButtonState();
    }

    // Dispatch event for other components
    publish(PUB_SUB_EVENTS.variantChange, {
      data: {
        sectionId: this.dataset.section,
        variant: this.currentVariant,
        productFormId: this.getAttribute('form')
      }
    });
  }

  updateOptions() {
    this.options = Array.from(this.querySelectorAll('select'), (select) => select.value);
  }

  updateMasterId() {
    // First try to match all options exactly (original behavior)
    this.currentVariant = this.getVariantData().find((variant) => {
      return !variant.options
        .map((option, index) => {
          return this.options[index] === option;
        })
        .includes(false);
    });

    // If no exact match found but we have options selected, try a more flexible approach
    // This mirrors the liquid template approach used in product-fabric-color-picker.liquid
    if (!this.currentVariant && this.options.length > 0) {
      // Find any available variant that contains all our selected options
      // regardless of their position or how many other options it has
      this.currentVariant = this.getVariantData().find((variant) => {
        // Check if all our selected options are contained in this variant's options
        return variant.available && this.options.every((selectedOption) => variant.options.includes(selectedOption));
      });
    }
  }

  updateURL() {
    if (!this.currentVariant || this.dataset.updateUrl === 'false') return;
    window.history.replaceState({}, '', `${this.dataset.url}?variant=${this.currentVariant.id}`);
  }

  updateShareUrl() {
    const shareButton = document.getElementById(`Share-${this.dataset.section}`);
    if (!shareButton || !shareButton.updateUrl) return;
    shareButton.updateUrl(`${window.shopUrl}${this.dataset.url}?variant=${this.currentVariant.id}`);
  }

  updateVariantInput() {
    const productFormId = this.getAttribute('form');
    const productForm = document.querySelector(`#${productFormId}`);
    if (!productForm) return;

    const input = productForm.querySelector('input[name="id"]');
    if (!input) return;

    if (this.currentVariant) {
      input.value = this.currentVariant.id;
    } else {
      // If we're in a transitional state, preserve the previous value if possible
      input.value = this.previousVariant?.id || '';
    }

    input.dispatchEvent(new Event('change', { bubbles: true }));
  }

  updateAddButtonState() {
    const productFormId = this.getAttribute('form');
    const productForm = document.querySelector(`#${productFormId}`);
    if (!productForm) return;

    const submitButton = productForm.querySelector('[type="submit"]');
    const buttonText = submitButton?.querySelector('span');
    if (!submitButton || !buttonText) return;

    // Enhanced state management based on variant availability
    if (!this.currentVariant) {
      if (this.previousVariant && this.previousVariant.available) {
        // If we had an available variant before and we're just transitioning,
        // keep the button in the available state
        submitButton.setAttribute('aria-disabled', 'false');
        submitButton.removeAttribute('disabled');

        // Check for pre-order tag
        const preOrderTag = Array.from(document.querySelectorAll('.product-tag')).some(
          (tag) => tag.dataset.tag === 'pre-order'
        );

        buttonText.textContent = preOrderTag ? window.variantStrings.preOrder : window.variantStrings.addToCart;
      } else {
        // Only show unavailable if we're certain
        submitButton.setAttribute('aria-disabled', 'true');
        submitButton.setAttribute('disabled', 'disabled');
        buttonText.textContent = window.variantStrings.unavailable;
      }
    } else if (!this.currentVariant.available) {
      // Variant exists but is sold out
      submitButton.setAttribute('aria-disabled', 'true');
      submitButton.setAttribute('disabled', 'disabled');
      buttonText.textContent = window.variantStrings.soldOut;
    } else {
      // Variant exists and is available
      submitButton.setAttribute('aria-disabled', 'false');
      submitButton.removeAttribute('disabled');

      // Check for pre-order tag
      const preOrderTag = Array.from(document.querySelectorAll('.product-tag')).some(
        (tag) => tag.dataset.tag === 'pre-order'
      );

      buttonText.textContent = preOrderTag ? window.variantStrings.preOrder : window.variantStrings.addToCart;
    }
  }

  updatePickupAvailability() {
    const pickUpAvailability = document.querySelector('pickup-availability');
    if (!pickUpAvailability) return;

    if (this.currentVariant && this.currentVariant.available) {
      pickUpAvailability.fetchAvailability(this.currentVariant.id);
    } else {
      pickUpAvailability.removeAttribute('available');
      pickUpAvailability.innerHTML = '';
    }
  }

  removeErrorMessage() {
    const section = this.closest('section');
    if (!section) return;

    const productForm = section.querySelector('product-form');
    if (productForm) productForm.handleErrorMessage();
  }

  renderProductInfo() {
    if (!this.currentVariant) return;

    const requestedVariantId = this.currentVariant.id;
    const sectionId = this.dataset.originalSection || this.dataset.section;

    // Update URL immediately to give faster feedback
    if (this.dataset.updateUrl !== 'false') {
      window.history.replaceState({}, '', `${this.dataset.url}?variant=${requestedVariantId}`);
    }

    // Fetch variant information - always get fresh data
    fetch(
      `${this.dataset.url}?variant=${requestedVariantId}&section_id=${
        this.dataset.originalSection || this.dataset.section
      }`
    )
      .then((response) => response.text())
      .then((responseText) => {
        // prevent unnecessary ui changes from abandoned selections
        if (this.currentVariant?.id !== requestedVariantId) return;

        this.processProductInfoUpdate(responseText, requestedVariantId, sectionId);
      })
      .catch((error) => {
        console.error('Error updating variant information:', error);
        // On error, ensure button stays in a usable state
        this.updateAddButtonState();
      });
  }

  processProductInfoUpdate(responseText, requestedVariantId, sectionId) {
    // Use requestAnimationFrame to batch DOM updates
    requestAnimationFrame(() => {
      const html = new DOMParser().parseFromString(responseText, 'text/html');

      // Update price
      const destination = document.getElementById(`price-${this.dataset.section}`);
      const source = html.getElementById(`price-${this.dataset.originalSection || this.dataset.section}`);
      if (source && destination) {
        destination.innerHTML = source.innerHTML;
        destination.classList.remove('visibility-hidden');
      }

      // Update SKU
      const skuSource = html.getElementById(`Sku-${this.dataset.originalSection || this.dataset.section}`);
      const skuDestination = document.getElementById(`Sku-${this.dataset.section}`);
      if (skuSource && skuDestination) {
        skuDestination.innerHTML = skuSource.innerHTML;
        skuDestination.classList.toggle('visibility-hidden', skuSource.classList.contains('visibility-hidden'));
      }

      // Update inventory
      const inventorySource = html.getElementById(`Inventory-${this.dataset.originalSection || this.dataset.section}`);
      const inventoryDestination = document.getElementById(`Inventory-${this.dataset.section}`);
      if (inventorySource && inventoryDestination) {
        inventoryDestination.innerHTML = inventorySource.innerHTML;
        inventoryDestination.classList.toggle(
          'visibility-hidden',
          inventorySource.classList.contains('visibility-hidden')
        );
      }

      // Dispatch event with updated information
      publish(PUB_SUB_EVENTS.variantChange, {
        data: {
          sectionId,
          html,
          variant: this.currentVariant
        }
      });

      // Ensure button state is updated after all information is processed
      this.updateAddButtonState();
    });
  }

  getVariantData() {
    this.variantData =
      this.variantData || JSON.parse(this.querySelector('[type="application/json"]')?.innerHTML || '[]');
    return this.variantData;
  }
}

customElements.define('variant-selects', VariantSelects);

/* Variant Radios */
class VariantRadios extends VariantSelects {
  constructor() {
    super();

    // Initialize data structures
    this.productMap = new Map();
    this.sizeAvailabilityMap = new Map();

    // Initialize variant data
    this.initVariantData();

    // Set up event handlers
    this.setupEventListeners();
  }

  // Initialize data from Shopify or preloaded JSON
  initVariantData() {
    // First try to get data from variant-availability-data element (preferred for v3)
    const variantDataElement = document.getElementById('variant-availability-data');
    if (variantDataElement) {
      try {
        const data = JSON.parse(variantDataElement.textContent);

        // Process all products and their variants
        if (data.products) {
          data.products.forEach((product) => {
            // Cache product by handle for quick lookup
            this.productMap.set(product.handle, product);

            // Pre-compute available sizes for each product
            const availableSizes = new Set();
            product.variants.forEach((variant) => {
              if (variant.available) {
                // Find the size option (usually option2)
                for (const [key, value] of Object.entries(variant)) {
                  if (key.startsWith('option') && value) {
                    availableSizes.add(value);
                  }
                }
              }
            });

            // Store available sizes with the product
            product.availableSizes = availableSizes;
          });
        }
      } catch (e) {
        console.error('Error parsing variant data:', e);
      }
    } else {
      // Fallback for v2 - use variant data from the current product
      const variants = this.getVariantData();
      if (variants && variants.length) {
        // Get the current product handle
        const productHandle = window.location.pathname.split('/products/')[1]?.split('?')[0];
        if (productHandle) {
          const availableSizes = new Set();
          variants.forEach((variant) => {
            if (variant.available) {
              variant.options.forEach((option) => {
                availableSizes.add(option);
              });
            }
          });

          // Create a simplified product object
          const product = {
            handle: productHandle,
            variants,
            availableSizes
          };

          this.productMap.set(productHandle, product);
        }
      }
    }
  }

  // Set up all event listeners
  setupEventListeners() {
    // Size selection change
    const sizeVariantInputs = this.querySelectorAll('.variant-radios input[type="radio"].size-peer');
    sizeVariantInputs.forEach((input) => {
      input.addEventListener('change', this.onSizeOptionChange.bind(this));
    });

    // Initialize color swatch availability on load
    window.addEventListener('DOMContentLoaded', () => {
      this.updateColorSwatchAvailability();
      this.updateSizeAvailability();
    });
  }

  // Handle size option change
  onSizeOptionChange(event) {
    const selectedSize = event.target.value;

    // Update size label if it exists
    const sizeLabel = document.querySelector('#selected-size');
    if (sizeLabel) {
      sizeLabel.textContent = selectedSize;
    }

    // Update color availability based on size
    this.updateColorSwatchAvailability(selectedSize);

    // Update main variant selection
    this.onVariantChange();
  }

  // Update color swatch availability based on selected size
  updateColorSwatchAvailability(selectedSize) {
    const swatchSelectors = document.querySelectorAll('swatch-selector');
    if (!swatchSelectors.length) return;

    // If no specific size is provided, find the currently selected one
    if (!selectedSize) {
      const sizeVariantInputs = this.querySelectorAll('.variant-radios input[type="radio"].size-peer');
      const selectedSizeInput = Array.from(sizeVariantInputs).find((input) => input.checked);
      selectedSize = selectedSizeInput?.value;
    }

    if (!selectedSize) return;

    // For each color swatch, check if the product has this size available
    swatchSelectors.forEach((swatch) => {
      const productHandle = swatch.getAttribute('handle');
      const product = this.productMap.get(productHandle);

      if (!product) return;

      const hasSizeVariant = product.availableSizes?.has(selectedSize);
      const swatchLabel = swatch.querySelector('.swatch-label');

      if (!swatchLabel) return;

      let strikeThrough = swatchLabel.querySelector('.color-swatch-unavailable');

      if (!hasSizeVariant) {
        // Size not available for this color - show strike-through
        if (!strikeThrough) {
          strikeThrough = document.createElement('div');
          strikeThrough.classList.add('color-swatch-unavailable', 'size-sold-out');
          swatchLabel.appendChild(strikeThrough);
        }
        swatch.classList.add('color-unavailable');
      } else {
        // Size is available - remove strike-through
        if (strikeThrough) {
          swatchLabel.removeChild(strikeThrough);
        }
        swatch.classList.remove('color-unavailable');
      }
    });
  }

  // Update size option availability based on the current product
  updateSizeAvailability() {
    // Get current product handle
    const currentPathname = window.location.pathname;
    const pathParts = currentPathname.split('/');
    const productsIndex = pathParts.indexOf('products');

    if (productsIndex === -1 || !pathParts[productsIndex + 1]) return;

    const currentProduct = pathParts[productsIndex + 1].split('?')[0];
    const product = this.productMap.get(currentProduct);

    if (!product || !product.availableSizes) return;

    // Get all size inputs
    const sizeInputs = this.querySelectorAll('.variant-radios input[type="radio"].size-peer');

    // Update each size input
    sizeInputs.forEach((input) => {
      const sizeValue = input.value;
      const sizeLabel = input.closest('label');

      // Set available/sold-out state
      if (product.availableSizes.has(sizeValue)) {
        input.classList.remove('sold-out');
        if (sizeLabel) sizeLabel.classList.remove('sold-out');
      } else {
        input.classList.add('sold-out');
        if (sizeLabel) sizeLabel.classList.add('sold-out');
      }
    });
  }

  // Override the parent class method
  updateOptions() {
    // Collect both size options and color options
    const sizeInputs = this.querySelectorAll('input[type="radio"].size-peer:checked');
    const colorInputs = document.querySelectorAll('swatch-selector input:checked');

    // Get all selected values
    const selectedOptions = [];

    // Add size selection if available
    if (sizeInputs.length > 0) {
      selectedOptions.push(sizeInputs[0].value);
    } else {
      // Check for variant-details-select dropdown (used in bra products)
      const detailsSelect = this.querySelector('variant-details-select select');
      if (detailsSelect && detailsSelect.value) {
        selectedOptions.push(detailsSelect.value);
      }
    }

    // Add color selection if available
    if (colorInputs.length > 0) {
      selectedOptions.push(colorInputs[0].value);
    }

    // Add any other options from other selector types
    const otherInputs = this.querySelectorAll('input[type="radio"]:checked:not(.size-peer)');
    otherInputs.forEach((input) => {
      // Avoid duplicates and only add if not already included
      if (!selectedOptions.includes(input.value)) {
        selectedOptions.push(input.value);
      }
    });

    // Debuggging
    console.log('Selected options:', selectedOptions);

    this.options = selectedOptions;
  }
}

customElements.define('variant-radios', VariantRadios);
